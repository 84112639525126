import { Link, navigate } from "gatsby";
import React, { useEffect, useState } from "react";
import {
  Accordion,
  Col,
  Container,
  Row,
  Tab,
  Tabs,
  Card,
  Form,
  InputGroup,
  FormControl,
  FormLabel,
  Modal,
} from "react-bootstrap";
import PhoneInput from "react-phone-input-2";
import {
  momentDate,
  NotificationSuccess,
  SortByField,
} from "../components/Helper";
import Layout from "../components/Layout";
import { getToken, isLoggedIn } from "../utils/auth";
import AddlocationPage from "./add-location";
import { CreateCampaign } from "./create-campaign";

export default function DashboardPage() {
  useEffect(() => {
    CheckLogin();
  }, []);
  async function CheckLogin() {
    var a = await isLoggedIn();
    if (!a) navigate(`/`);
  }

  const [title, setTitle] = useState("Dashboard");
  const email01 =
    typeof window !== "undefined"
      ? new URLSearchParams(window.location.search).get("email")
      : "";
  const code01 =
    typeof window !== "undefined"
      ? new URLSearchParams(window.location.search).get("code")
      : "";

  const [Values, setValues] = useState([]);
  useEffect(() => {
    BrandCampaigns(setValues);
  }, []);

  function ReloadCampaigns() {
    BrandCampaigns(setValues);
  }

  useEffect(() => {
    // Update the document title using the browser API
    var access = localStorage.getItem("loginaccesstokec");
    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + access + "");

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + access + "");
    myHeaders.append(
      "Cookie",
      ".AspNet.Cookies=57RvYIqo0Pso91eOlgFj0n5RwFLFslYjc7FgVI2mod44ZEF0Nn2quMzC4SiCeXSvapAP_U4a32GZnEEw9xq63hoQuL6z7w2_dmaywHgL-E74agBk7BJJxNymbE0I6ty-RHSWk_Po7SiYojvLl5CLxo0MYVLpl948lvL8y6OL-gm3wQYSmQaOpDo_OEajyrs0VzqcQB2DlaarUr-sz7ji86KMoxEMDHJDQUiP9moMI5W5HvqaV2ay1O1s5g2kDx5OqcXU_aefxRvxxMkPsUSmM9JZ-sZPrcS5YBYVE_QOCOR-iHljXlb-oFgDKumDfW2_C_MjDOe2baKcbRhYcTltBQWXIo1b1u2vPSjB5iBDaEI8WFrzwSOcUDV9dPH9oIach5KvDyEy-bOBiKMb3czBksEPC4bAFc9uvSdWfW2D5LZyC1KBlX-HCLG_iTprkLuztfldO6HLeZxxCmrQLcbDyxGgKI0DkSpxQP14C1dn87mfVxW7E8a5qKMReip0_2qYG7R61QHkroYRjzKXd7hPhA"
    );

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(`${window.$apiurl}/api/Merchant/Vouchers/History`, requestOptions)
      .then((response) => response.text())
      .then((result) => {
        var json = JSON.parse(result);
        document.getElementById("vouchercount").innerText = 0;
      })
      .catch((error) => console.log("error", error));

    var myHeaders01 = new Headers();
    myHeaders01.append("Content-Type", "application/json");

    var raw01 = JSON.stringify({
      email: email01,
      GuidCode: code01,
    });

    var requestOptions01 = {
      method: "POST",
      headers: myHeaders01,
      body: raw01,
      redirect: "follow",
    };

    fetch(`${window.$apiurl}/api/Account/GuidLogin`, requestOptions01)
      .then((response) => response.text())
      .then((result) => {
        var json = JSON.parse(result);
        if (json.access_token) {
          localStorage.setItem("loginaccesstokec", json.access_token);
          localStorage.setItem("loginemail", json.userName);

          document.querySelector(".registerbutton").style.display = "none";
          document.querySelector(".loginbutton").style.display = "none";
          document.querySelector(".logoutbutton").style.display = "block";
          document.querySelector(".welocmetext").style.display = "block";
          document.getElementById("Name").innerHTML =
            localStorage.getItem("loginemail");
        }
      })
      .catch((error) => console.log("error", error));
  });

  return (
    <Layout>
      <main>
        <section className="Merchant-Dashboard">
          <Container>
            <Row>
              <div className="col-xl-12 col-lg-12 col-md-12 pt-3 mx-auto text-center">
                <h1 className="fs-48 fw-800 mb-5">{title}</h1>
              </div>
              <div className="col-xl-3 col-lg-3 col-md-4 mt-4">
                <ul
                  className="list-group list-group-flush"
                  id="myTab"
                  role="tablist"
                >
                  <li
                    className="list-group-item border-0 pb-3"
                    onClick={() => {
                      setTitle("Dashboard");
                    }}
                  >
                    <Link
                      to=""
                      className="text-muted text-decoration-none active"
                      id="Dashboard-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#Dashboard"
                      type="button"
                      role="tab"
                      aria-controls="Dashboard"
                      aria-selected="true"
                    >
                      <span>
                        {" "}
                        <img
                          src="../img/Home.svg"
                          alt="Home icon"
                          className="me-3"
                        />
                      </span>
                      Dashboard
                    </Link>
                  </li>
                  <li
                    className="list-group-item border-0 pb-3"
                    onClick={() => setTitle("Campaigns")}
                  >
                    <Link
                      to=""
                      className="text-muted text-decoration-none"
                      id="Campaigns-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#Campaigns"
                      type="button"
                      role="tab"
                      aria-controls="Campaigns"
                      aria-selected="false"
                    >
                      <span>
                        {" "}
                        <img
                          src="../img/Campaign.svg"
                          alt="Home icon"
                          className="me-3"
                        />
                      </span>
                      Campaigns
                    </Link>
                  </li>
                  <li
                    className="list-group-item border-0 pb-3"
                    onClick={() => setTitle("Merchant")}
                  >
                    <Link
                      to=""
                      className="text-muted text-decoration-none"
                      id="Merchant-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#Merchant"
                      type="button"
                      role="tab"
                      aria-controls="Merchant"
                      aria-selected="false"
                    >
                      <span>
                        {" "}
                        <img
                          src="../img/shoe-shop-2.svg"
                          alt="Home icon"
                          className="me-3"
                        />
                      </span>
                      Merchant
                    </Link>
                  </li>
                  <li
                    className="list-group-item border-0"
                    onClick={() => setTitle("Account")}
                  >
                    <Link
                      to=""
                      className="text-muted text-decoration-none"
                      id="Account-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#Account"
                      type="button"
                      role="tab"
                      aria-controls="Account"
                      aria-selected="false"
                    >
                      <span>
                        {" "}
                        <img
                          src="../img/settings.svg"
                          alt="Home icon"
                          className="me-3"
                        />
                      </span>
                      Settings
                    </Link>
                  </li>
                </ul>
              </div>

              <div className="col-xl-9 col-lg-9 col-md-8">
                <div
                  className={`tab-pane fade show active ${
                    title === "Dashboard" ? "" : "d-none"
                  }`}
                  id="Dashboard"
                  role="tabpanel"
                  aria-labelledby="Dashboard-tab"
                >
                  <Row>
                    <div className="col-xl-10 col-lg-12 col-md-12 mb-5">
                      <div className="Dashboard-img pt-5 px-5">
                        <div className="pb-5">
                          <h1 className="fs-48 fw-800 text-white pt-5">
                            Create and launch{" "}
                          </h1>
                          <h1 className="fs-48 fw-800 mb-5 text-white">
                            your campaign
                          </h1>
                        </div>
                        <Link
                          to=""
                          className="text-white fw-airlight text-decoration-none fs-24 "
                        >
                          <span>
                            <img
                              src="../img/Watch.svg"
                              alt="watch btn"
                              className="me-3"
                            />
                          </span>
                          Watch how it works
                        </Link>
                      </div>
                    </div>
                    <div className="col-12">
                      <h5 class="fw-normal fs-36  fw-airlight">Overview</h5>
                    </div>
                    <div className="col-12 mb-5">
                      <Row>
                        <div className="Campaigns-Card text-center col-xl-4 col-lg-4 col-md-12 pt-5 me-xl-3 me-lg-3">
                          <h1
                            className="fs-48 fw-800 mb-1 mt-4 pe-4 primary-color"
                            id="TotalCampaigns"
                          >
                            0
                          </h1>
                          <h4 className="text-muted fw-airlight pe-4">
                            Total Campaigns
                          </h4>
                        </div>
                        <div className="Vouchers-Card text-center col-xl-4 col-lg-4 col-md-12 pt-5">
                          {" "}
                          <h1
                            className="fs-48 fw-800 mb-1 mt-4 pe-4 primary-color "
                            id="vouchercount"
                          >
                            0
                          </h1>
                          <h4 className="text-muted fw-airlight pe-4">
                            Vouchers Redeemed
                          </h4>
                        </div>
                      </Row>
                    </div>
                    <div className="col-12">
                      <h5 class="fw-normal fs-36  fw-airlight mb-4">
                        BOGO Brand Ambassador Program
                      </h5>
                    </div>
                    <div className="col-xl-10 col-lg-12 col-md-12 mb-5 Dashboard-lighBg px-4 pt-5">
                      <Row className="pb-5 border-bottom">
                        <div className="col-xl-8 col-lg-8 col-md-7">
                          <h3 className="fw-airlight  lh-36 mb-4">
                            Launch an influencer campaign and grow your reach
                            online
                          </h3>

                          <div class="symbol-group symbol-hover">
                            <div class="symbol symbol-circle">
                              <img alt="Pic" src="../img/userpic3.png" />
                            </div>
                            <div class="symbol symbol-circle">
                              <img alt="Pic" src="../img/userpic.png" />
                            </div>
                            <div class="symbol symbol-circle">
                              <img alt="Pic" src="../img/userpic2.png" />
                            </div>
                            <div class="symbol symbol-circle symbol-light-danger">
                              <span class="symbol-label  fw-airlight fs-13">
                                +100
                              </span>
                            </div>
                          </div>
                        </div>
                        <div className="col-xl-4 col-lg-4 col-md-3 text-end image-vid">
                          <img
                            src="../img/PlayBack.svg"
                            alt="Play"
                            className="PlayBtn"
                          />
                          <img
                            src="../img/Bitmap.png"
                            className="thumbnail-img"
                            alt="video img"
                          />
                        </div>
                      </Row>
                      <div className="text-center pt-2">
                        <Link className="primary-color  text-decoration-none fs-24">
                          <span className=" pt-3">
                            Launch a Brand Ambassador Campaign
                          </span>
                        </Link>
                      </div>
                    </div>
                  </Row>
                </div>
                <div
                  className={`tab-pane fade ${
                    title === "Campaigns" ? "" : "d-none"
                  }`}
                  id="Campaigns"
                  role="tabpanel"
                  aria-labelledby="Campaigns-tab"
                >
                  {title === "Campaigns" && (
                    <CampaignsComponent
                      Values={Values}
                      ReloadCampaigns={ReloadCampaigns}
                    />
                  )}
                </div>
                <div
                  className={`tab-pane fade ${
                    title === "Merchant" ? "" : "d-none"
                  }`}
                  id="Merchant"
                  role="tabpanel"
                  aria-labelledby="Merchant-tab"
                >
                  {title === "Merchant" && <AddlocationPage Header={false} />}
                </div>
                <div
                  className={`tab-pane fade ${
                    title === "Account" ? "" : "d-none"
                  }`}
                  id="Account"
                  role="tabpanel"
                  aria-labelledby="Account-tab"
                >
                  {title === "Account" && <SettingsComponent />}
                </div>
              </div>
            </Row>
          </Container>
        </section>
      </main>
    </Layout>
  );
}

function SettingsComponent({}) {
  const [Values, setValues] = useState({});
  const [ModelPassword, setModelPassword] = useState();
  useEffect(() => {
    BogoUserInfo(setValues);
  }, []);

  const SaveFun = () => {
    var phone = "";
    var a = document.querySelector(`#FirstPhone [type="tel"]`)?.value;
    var b = document.getElementById("LastPhone")?.value;
    if (a !== "" && b !== "") phone = a + b;

    var value = {
      ...Values,
      FirstName: document.getElementById("FirstName")?.value,
      PhoneNumber: phone,
      Email: document.getElementById("Email")?.value,
    };
    BogoUserEdit(value);
  };
  return (
    <div>
      <Col xl="7" lg="7" md="12" className="mb-4">
        <Row>
          <h2 className="fw-airlight">Account Settings</h2>
          <Form>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label className="fs-13 bogo-par form-label">
                Owner/Manager Information
              </Form.Label>
              <Form.Control
                type="text"
                id="FirstName"
                placeholder=""
                defaultValue={Values?.FirstName}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label className="fs-13 bogo-par form-label">
                E-mail address
              </Form.Label>
              <Form.Control
                type="email"
                id="Email"
                placeholder=""
                defaultValue={Values?.Email}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Text className="primary-color pt-3">
                Change password
              </Form.Text>
            </Form.Group>
            <FormLabel className="fs-13 bogo-par">Mobile number</FormLabel>
            <InputGroup className="mb-2" id="FirstPhone">
              <PhoneInput
                country="ae"
                enableAreaCodes={true}
                value={Values?.PhoneNumber?.slice(0, 4)}
                onChange={(e) => {
                  document.getElementById("FirstPhone").value = e;
                }}
              />
              <input type="hidden" id="FirstPhone" />
              <FormControl
                type="tel"
                aria-label="Text input with dropdown button"
                name="Phone"
                id="LastPhone"
                defaultValue={Values?.PhoneNumber?.slice(
                  4,
                  Values?.PhoneNumber?.length
                )}
              />
            </InputGroup>
          </Form>
        </Row>
      </Col>
      <Col xl="8" lg="8" md="12">
        <h3>Payment & Subscription</h3>
        <Card className="bg-light rounded border-0 shadow-sm">
          <Card.Body>
            <Row>
              <Col xl="7" lg="7" md="7" xs="7">
                <p className="opacity-50 fw-airlight">
                  Subsriber since Apr 2021
                </p>
                <p className="opacity-50 fw-airlight">
                  <img src="../img/visa.png" alt="visa" className="pe-2" />
                  {"45122369487".replace(/\d{4}/, "****")}
                </p>
              </Col>
              <Col xl="5" lg="5" md="5" xs="5" className="text-end">
                <p>Gold Member</p>
              </Col>
            </Row>
          </Card.Body>
          <Card.Footer className="bg-light">
            <div className="text-center my-2 ">
              <Link to="/subscription" className="primary-color text-decoration-none">
                Manage Subscription
              </Link>
            </div>
          </Card.Footer>
        </Card>

        {/* <button
          className="btn btn-primary mt-3 text-white border-0 btn-google w-100 mb-5"
          onClick={() => SaveFun()}
        >
          Save
        </button> */}
      </Col>
    </div>
  );
}

function CampaignsComponent({ Values, ReloadCampaigns }) {
  return (
    <div>
      <Col xl="8" lg="8" md="12" className="toggle-text mb-4">
        <input id="r1" type="radio" name="test" value="Voucher" checked />
        <label for="r1">Voucher</label>
        <input id="r2" type="radio" name="test" value="Brand Ambassador" />
        <label for="r2">Brand Ambassador</label>
      </Col>
      <div className="col-xl-10 col-lg-12 col-md-12">
        <Row>
          <Accordion defaultActiveKey={["0"]} alwaysOpen>
            <Accordion.Item eventKey="0" className="border-0 bg-transparent">
              <Col xl="3">
                <Accordion.Header className="fw-bolder">
                  Dubai Marina
                </Accordion.Header>
              </Col>
              <Accordion.Body>
                <div className="col-12 mb-5">
                  <Row>
                    <div className="Campaigns-Card text-center col-xl-4 col-lg-4 col-md-12 pt-5 me-xl-3 me-lg-3">
                      <h1
                        className="fs-48 fw-800 mb-1 mt-4 pe-4 primary-color"
                        id="TotalCampaigns"
                      >
                        6
                      </h1>
                      <h4 className="text-muted fw-airlight pe-4">
                        Total Campaigns
                      </h4>
                    </div>
                    <div className="Vouchers-Card text-center col-xl-4 col-lg-4 col-md-12 pt-5">
                      {" "}
                      <h1
                        className="fs-48 fw-800 mb-1 mt-4 pe-4 primary-color "
                        id="vouchercount"
                      >
                        45
                      </h1>
                      <h4 className="text-muted fw-airlight pe-4">
                        Vouchers Redeemed
                      </h4>
                    </div>
                  </Row>
                </div>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
          <Tabs
            defaultActiveKey="active"
            transition={false}
            id=""
            className="mb-3 tabs-dash border-0"
          >
            <Tab eventKey="active" title="Active">
              <ValuesByTab
                Values={Values}
                state={0}
                ReloadCampaigns={ReloadCampaigns}
              />
            </Tab>
            <Tab eventKey="completed" title="Completed">
              <ValuesByTab
                Values={Values}
                state={1}
                ReloadCampaigns={ReloadCampaigns}
              />
            </Tab>
            <Tab eventKey="draft" title="Draft">
              <ValuesByTab
                Values={Values}
                state={2}
                ReloadCampaigns={ReloadCampaigns}
              />
            </Tab>
          </Tabs>
        </Row>
      </div>
    </div>
  );
}

function ValuesByTab({ Values, state, ReloadCampaigns }) {
  const [EditDetail, setEditDetail] = useState();

  function isDate(item, name) {
    return !!item.Timings.find((x) => x?.DayName === name);
  }

  return (
    <div>
      <EditCampaign
        show={EditDetail}
        setShow={setEditDetail}
        ReloadCampaigns={ReloadCampaigns}
      />
      {SortByField(Values, "Id")
        .filter((x) => x.state === state)
        .map((item) => (
          <Col xl="12">
            <Card className="border-0 rounded bg-light mb-3">
              <Card.Body>
                <Row>
                  <Col xl="7" lg="7" md="6" xs="6">
                    <button className="w-30 fs-14 px-5 btn btn-primary btn btn-primary rounded-pill mb-2">
                      {item?.TypeName}
                    </button>
                    <span className="ml-12 primary-color">
                      {item?.BranchesName}
                    </span>
                    <p className="mb-1">
                      Buy 1:{" "}
                      <span className="fw-airlight">{item?.Type?.ToBuy}</span>
                    </p>
                    <p className="mb-1">
                      Get 1:{" "}
                      <span className="fw-airlight">{item?.Type?.Bonus}</span>
                    </p>
                  </Col>
                  <Col xl="5" lg="5" md="6" xs="6" className="text-end">
                    <p
                      className="primary-color"
                      onClick={() => {
                        item.TypeNumber = item?.Type?.Type ?? 0;
                        setEditDetail(item);
                      }}
                    >
                      {" "}
                      <img
                        src="../img/manage.png"
                        alt="manage"
                        className="pe-2"
                      />{" "}
                      Manage{" "}
                    </p>
                    <p className="text-muted fs-13 mb-1 justify-content-end">
                      Launched:
                      {momentDate(item?.Created, " mmmm dd, yyyy")}
                    </p>
                    <span className="d-flex justify-content-end">
                      <span
                        className={`${
                          isDate(item, "Sunday") && "primary-color"
                        } pe-1`}
                      >
                        S
                      </span>
                      <span
                        className={`${
                          isDate(item, "Monday") && "primary-color"
                        } pe-1`}
                      >
                        {" "}
                        M
                      </span>
                      <span
                        className={`${
                          isDate(item, "Tuesday") && "primary-color"
                        } pe-1`}
                      >
                        {" "}
                        T
                      </span>
                      <span
                        className={`${
                          isDate(item, "Wednesday") && "primary-color"
                        } pe-1`}
                      >
                        {" "}
                        W{" "}
                      </span>
                      <span
                        className={`${
                          isDate(item, "Thursday") && "primary-color"
                        } pe-1`}
                      >
                        {" "}
                        T
                      </span>
                      <span
                        className={`${
                          isDate(item, "Friday") && "primary-color"
                        } pe-1`}
                      >
                        {" "}
                        F{" "}
                      </span>
                      <span
                        className={`${
                          isDate(item, "Saturday") && "primary-color"
                        } pe-1`}
                      >
                        {" "}
                        S
                      </span>
                    </span>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
        ))}
    </div>
  );
}

const EditCampaign = ({ show, setShow, ReloadCampaigns }) => {
  const handleCloseModel = () => {
    setShow(false);
  };

  return (
    <Modal
      show={!!show?.Id}
      onHide={handleCloseModel}
      animation={true}
      size="lg"
    >
      <Modal.Header closeButton>
        <Modal.Title>Edit : {show?.Type?.ToBuy}</Modal.Title>
      </Modal.Header>
      <Modal.Body className="col-12">
        <CreateCampaign
          Edit={true}
          Values={show}
          ReloadCampaigns={ReloadCampaigns}
        />
      </Modal.Body>
    </Modal>
  );
};

async function BrandCampaigns(setValues) {
  return await fetch(`${window.$apiurl}/api/Merchant/Campaigns`, {
    method: "get",
    withCredentials: true,
    headers: {
      Accept: "application/json, text/plain, */*",
      Authorization: `bearer ${getToken()}`,
      "X-FP-API-KEY": "iphone",
      "Content-Type": "application/json",
    },
  })
    .then(async (response) => {
      const statusCode = await response.status;
      const data = await response.json();
      return Promise.all([statusCode, data]);
    })
    .then(async (res) => {
      if (res[0] === 200) {
        document.getElementById("TotalCampaigns").innerText = res[1].length;

        res[1].forEach((element) => {
          // branch
          var str = "";
          element.Branches.forEach((Branch) => {
            str += Branch.Branch + ", ";
          });
          str = str.substring(0, str.length - 2);
          element.BranchesName = str;

          // type
          switch (element.Type.Type) {
            case 0:
              element.TypeName = "Buy 1 Get 1";
              break;
            case 1:
              element.TypeName = "Buy X Get Y";
              break;
            case 2:
              element.TypeName = "Buy Bunde Get Unit";
              break;
            default:
              element.TypeName = "Buy 1 Get 1";
              break;
          }

          // Timings
          //element.Timings;
        });

        setValues(res[1]);
      }
    })
    .catch((error) => {
      console.error(error);
    });
}

async function BogoUserInfo(setValues) {
  return await fetch(`${window.$apiurl}/api/Merchant/BogoUserInfo`, {
    method: "get",
    withCredentials: true,
    headers: {
      Accept: "application/json, text/plain, */*",
      Authorization: `bearer ${getToken()}`,
      "X-FP-API-KEY": "iphone",
      "Content-Type": "application/json",
    },
  })
    .then(async (response) => {
      const statusCode = await response.status;
      const data = await response.json();
      return Promise.all([statusCode, data]);
    })
    .then(async (res) => {
      if (res[0] === 200) {
        setValues(res[1]);
      }
    })
    .catch((error) => {
      console.error(error);
    });
}

async function BogoUserEdit(Value) {
  return await fetch(`${window.$apiurl}/api/Merchant/ChangeBogoUserInfo`, {
    method: "PATCH",
    withCredentials: true,
    headers: {
      Accept: "application/json, text/plain, */*",
      Authorization: `bearer ${getToken()}`,
      "X-FP-API-KEY": "iphone",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(Value),
  })
    .then(async (response) => {
      const statusCode = await response.status;
      const data = await response.json();
      return Promise.all([statusCode, data]);
    })
    .then(async (res) => {
      if (res[0] === 200) {
      }
    })
    .catch((error) => {
      console.error(error);
    });
}
